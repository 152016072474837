import { handleize } from 'js/utils';

export const normalizeServices = ({ service }) => {
  const {
    title,
    image,
    fields: { handle },
    b2bPresentation: b2b = {},  // variable name needs to match a type handle (e.g. `b2c`)
    b2cPresentation: b2c = {},  // variable name needs to match a type handle (e.g. `b2c`)
    ecommPresentation: ecomm = {},  // variable name needs to match a type handle (e.g. `b2c`)
    execPresentation: exec = {},  // variable name needs to match a type handle (e.g. `b2c`)
  } = service;

  const presentations = { b2b, b2c, ecomm, exec };
  const fallbackPresentationId = service.fallbackPresentationId || '';
  const fallbackDataPoints = service.fallbackDataPoints || [];

  const forBusinessTypes = Object.entries(presentations)
    .filter(([handle, presentation]) => presentation && presentation.enabled)
    .map(([handle, {presentationId, dataPoints}]) => ({
      type: handle,
      presentationId: presentationId || fallbackPresentationId,
      dataPoints: (dataPoints || fallbackDataPoints).map(handleize),
    }));

  return { title, image, handle, forBusinessTypes };
};

export const getActiveServices = state => {
  return state.active.services
    .map(handle => state.data.services.find(service => service.handle === handle))
    .filter(({ forBusinessTypes }) => forBusinessTypes.some(({ type }) => type === state.active.businessType))
    .map(service => {
      const { title, handle, forBusinessTypes } = service;
      const { dataPoints, presentationId } = forBusinessTypes
        .find(({ type }) => type === state.active.businessType);

      return { title, handle, presentationId, dataPoints };
    });
};
