import React from 'react';
import { css } from '@emotion/core';

import Option from 'components/Option';
import Container from 'components/Container';
import Section, { SectionHeading, SectionGrid } from 'components/Section';

import { useStateContext, actions } from 'state';

const Services = props => {
  const [state, dispatch] = useStateContext();

  const toggleServiceAndDataPoints = service => {
    const { handle } = service;
    const hasHandle = state.active.services.includes(handle);
    const type = hasHandle ? actions.disableService : actions.enableService;

    dispatch({ type, service });
  };

  return (
    <Container
      css={[ serviceStyles, state.active.businessType === null && disabledServicesStyles ]}
      {...props}
    >

      <Section>
        <SectionHeading>Services</SectionHeading>

        <SectionGrid>
          {state.data.services
            .filter(({ forBusinessTypes }) => forBusinessTypes.some(({ type }) => state.active.businessType === type))
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(service => (
              <Option
                key={service.handle}
                title={service.title}
                image={service.image}
                selected={state.active.services.includes(service.handle)}
                css={{borderColor: '#000'}}
                onClick={() => toggleServiceAndDataPoints({ handle: service.handle })}
              />
            ))
          }
        </SectionGrid>
      </Section>

    </Container>
  );
}

export default Services;

const disabledServicesStyles = {
  opacity: 0.4,
  pointerEvents: 'none',
};

const serviceStyles = css`
  opacity: 1;
  transition: opacity 300ms linear;
  margin: 0;
`;
