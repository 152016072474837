/* eslint-ignore react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Services from 'components/Services';
import Container from 'components/Container';
import { BurntButton } from 'components/Link';
import BusinessInformation from 'components/BusinessInformation';
import LoadingInterstitial from 'components/LoadingInterstitial';

import { runAudit } from 'api/audit';
import { normalizeServices } from 'api/services';
import { normalizeDataPoints } from 'api/data-points';
import { normalizeBusinessTypes } from 'api/business-types';

import { useStateContext, actions } from 'state';


const IndexPage = props => {
  const [state, dispatch] = useStateContext();
  const businessTypes = props.data.businessTypes.edges.map(normalizeBusinessTypes);
  const services = props.data.services.edges.map(normalizeServices);
  const dataPoints = props.data.dataPoints.edges.map(normalizeDataPoints);

  useEffect(() => {
    dispatch({ type: actions.emptyState });

    dispatch({
      group: 'data',
      value: { businessTypes, services, dataPoints },
      type: actions.updateBusinessInfo,
    });
  }, []);  // eslint-disable-line

  return (
    <Container mx='auto'>
      {/* <div css={{position: 'fixed', top: 0, left: 0, zIndex: 666 }}>
        <button onClick={() => console.log('CURRENT STATE', { ...state })}>state</button>
      </div> */}

      <BusinessInformation businessTypes={businessTypes} />

      <Services services={services} mb={20} />

      <BurntButton
        onClick={() => runAudit({ state, dispatch })}
        css={{ marginLeft: 'auto',
            cursor: 'pointer',
            backgroundImage: 'linear-gradient(115deg, #4a1fff 0%, #4a1fff 100%)',
            boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.16)',
            borderRadius: 13,
            color: '#FFF'
        }}
      >
        Run Audit
      </BurntButton>

      {state.interstitial.active && <LoadingInterstitial />}

    </Container>
  );
};

export default IndexPage;


export const query = graphql`
  query TypesAndServicesPageQuery {
    businessTypes: allTypesJson {
      edges {
        businessType: node {
          title
          presentationId
          image {
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          fields {
            handle
          }
        }
      }
    }
    services: allServicesJson {
      edges {
        service: node {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          fallbackPresentationId
          fallbackDataPoints
          b2bPresentation {
            enabled
            presentationId
            dataPoints
          }
          b2cPresentation {
            enabled
            presentationId
            dataPoints
          }
          ecommPresentation {
            enabled
            presentationId
            dataPoints
          }
          execPresentation {
            enabled
            presentationId
          }
          fields {
            handle
          }
        }
      }
    }
    dataPoints: allDataPointsJson {
      edges {
        dataPoint: node {
          title
          inputs
          fields {
            handle
          }
        }
      }
    }
  }
`;
