import { handleize } from 'js/utils';
import { getActiveServices } from 'api/services';

export const normalizeDataPoints = ({ dataPoint }) => {
  const { title, fields: { handle }} = dataPoint;
  const inputs = (dataPoint.inputs || [])
    .filter(input => input)
    .map(input => ({ title: input, handle: handleize(input) }));

  return { title, inputs, handle };
};

const getActiveDataPoints = state => {
  return getActiveServices(state)
    .map(({ title: serviceTitle, handle, dataPoints }) => {
      return dataPoints
        .map(dataPoint => state.data.dataPoints.find(({ handle }) => handle === dataPoint))
        .map(dataPoint => ({ ...dataPoint, service: serviceTitle }));
    })
    .flat();
};

export const getActiveDataPointsInputs = state => {
  const activeDataPointsWithInputs = getActiveDataPoints(state)
    .filter(dataPoint => dataPoint.inputs.length);

  const data = activeDataPointsWithInputs
    .reduce((dataPointsGroup, dataPoint) => ({
      ...dataPointsGroup,
      [dataPoint.handle]: {
        title: dataPoint.title,
        handle: dataPoint.handle,
        inputs: dataPoint.inputs.reduce((inputs, input) => ({
          ...inputs,
          [input.handle]: {
            title: input.title,
            handle: input.handle,
          },
        }), {}),
      },
    }), {});

  const inputs = activeDataPointsWithInputs
    .reduce((group, dataPoint) => ({
      ...group,
      [dataPoint.handle]: dataPoint.inputs.reduce((inputs, input) => ({
        ...inputs,
        [input.handle]: ''
      }), {})
    }), {});

  return { data, inputs: { ...inputs, ...state.modal.inputs }};
};
