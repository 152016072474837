import get from 'lodash.get';
import { navigate } from 'gatsby';
import isEmpty from 'lodash.isempty';

import { getActiveServices } from 'api/services';
import { getActiveBusinessType } from 'api/business-types';
import { getActiveDataPointsInputs } from 'api/data-points';

import { actions } from 'state';

const updateModalInputs = ({ dispatch, inputs }) => dispatch({
  inputs,
  type: actions.updateModalInputs,
});

const updateModalData = ({ dispatch, data }) => dispatch({
  data,
  type: actions.updateModalData,
});

const openModal = ({ dispatch, name, close }) => dispatch({
  close,
  active: name,
  type: actions.updateModalActive,
});

const toggleLoadingInterstitial = ({ dispatch, active }) => dispatch({
  active,
  type: actions.updateInterstitialActive,
});

const updateInterstitialProgress = ({ dispatch, progress, message }) => dispatch({
  progress,
  message,
  type: actions.updateInterstitialProgress,
});

const toggleErrorModal = ({ dispatch, error }) => dispatch({
  type: actions.updateModalActive,
  active: 'error',
  error,
});

const validateRequiredFields = async state => {
  console.log('(2) validateRequiredFields state', state);
  const requiredStateProperties = [
    'info.name',
    'info.url',
    'active.businessType',
    'active.services',
  ];

  const invalidProperties = requiredStateProperties
    .filter(property => get(state, property).length === 0 || get(state, 'info.url').length <= 7)
    .map(property => property.split('.').reverse()[0]);

  if (invalidProperties.length) {
    throw new Error(`Invalid Properties: ${invalidProperties.join(', ')}`);
  }

  return;
};

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    if (!file) resolve();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const buildRequestBody = async state => {
  const { active, social, inputs, info: { logo, ...businessInfo }, googleAnalytics } = state;
  const businessType = getActiveBusinessType(state);
  const services = getActiveServices(state);
  const base64 = await getBase64(logo);

  console.log('(4.1) buildRequestBody state', state);
  console.log('(4.2) buildRequestBody businessInfo', businessInfo);

  return {
    businessType,
    services,
    businessInfo,
    inputs,
    active,
    social,
    logo: base64,
    googleAnalytics,
    state,
  };
};

const sendRequest = async state => {
  console.log('(3) sendRequest state', state);
  const body = await buildRequestBody(state);

  const options = {
    body: JSON.stringify(body),
    mode: 'cors',
    method: 'POST',
    credentials: 'include',
  };

  console.log('body', body);

  return fetch('/.netlify/functions/audit', options)
    .then(res => res.json())
    .then(res => ({ ...res, ...body }))
    .catch(error => {
      throw new Error(error);
    });
};

const waitForInputsResponse = ({ state, dispatch }) => {
  return new Promise(resolve => {
    const { data, inputs } = getActiveDataPointsInputs(state);

    if (!isEmpty(data)) {
      updateModalData({ dispatch, data });
      updateModalInputs({ dispatch, inputs: { ...inputs, confirm: resolve } });
      openModal({ dispatch, name: 'inputs' });
    } else {
      resolve({});
    }
  });
};

const recursivelyGetProgress = ({ resource, handler, timeout = 2500 }) => {
  return new Promise(resolve => {
    setTimeout(() => {
      fetch(`https://blackbox-audit-assets.storage.googleapis.com/${resource}`)
        .then(res => res.json())
        .catch(error => resolve(recursivelyGetProgress({ resource, handler, timeout })))
        .then(handler(resolve, resource))
    }, timeout);
  });
};

const updateProgress = async ({ presentationId, businessType, services, state, dispatch }) => {
  const progress = {
    presentations: 0,
    services: 0,
    total: (services.length * 2) + 1,
  };

  const timeouts = {
    results: 15000,
    presentations: 10000,
    service: 5000,
  };

  const getCurrentMessage = ({ complete, count }) => {
    if (complete === undefined) {
      return state.interstitial.message;
    } else if (complete === 0) {
      return `Copying slides from the ${businessType.title} presentation`;
    } else if (complete < count) {
      return `Copying slides from the ${services[complete - 1].title} presentation`;
    } else if (complete === count) {
      return 'Replacing placeholder content';
    } else {
      return 'Running audits';
    }
  };

  const handler = (resolve, resource) => data => {
    const { active, complete, count, body, type, handle, filename } = data;
    console.log({ active, complete, count, body, type, handle, filename });

    const resultsComplete = type === 'results' && active === false && body !== undefined;
    const presentationsComplete = type === 'presentations' && complete === count;
    const serviceComplete = type === 'service' && active === false;

    if (resultsComplete || presentationsComplete || serviceComplete) {
      resolve();
    } else {
      resolve(recursivelyGetProgress({ resource, handler, timeout: timeouts[type] }));
    }

    if (type === 'service' && active === false) {
      progress.services = progress.services + 1;
    }

    if (type === 'presentations') {
      progress.presentations = complete;
    }

    updateInterstitialProgress({
      dispatch,
      message: getCurrentMessage({ complete, count }),
      progress: ((progress.presentations + progress.services) / progress.total) * 100,
    });
  };

  await Promise.all([
    recursivelyGetProgress({ resource: `${presentationId}.json`, handler }),
    recursivelyGetProgress({ resource: `${presentationId}-presentations.json`, handler }),
    ...services.map(({ handle }) =>
      recursivelyGetProgress({ resource: `${presentationId}-${handle}.json`, handler })),
  ]);

  return;
};

export const runAudit = async ({ state, dispatch }) => {
  try {
    console.log('(1) runAudit state', state);
    await validateRequiredFields(state);

    const { inputs } = await waitForInputsResponse({ state, dispatch });

    toggleLoadingInterstitial({ active: true, dispatch });
    updateInterstitialProgress({ dispatch, message: 'Creating blank presentation' });

    const { presentationId, businessType, services } = await sendRequest({ ...state, inputs });

    console.log("presentationId: " + presentationId);
    console.log("businessType: " + businessType);
    console.log("services: " + services);

    await updateProgress({ presentationId, businessType, services, state, dispatch });

    toggleLoadingInterstitial({ active: false, dispatch });
    updateInterstitialProgress({ dispatch, progress: 0 });
    navigate(`/results?presentationId=${presentationId}`);
  } catch (error) {
    toggleLoadingInterstitial({ active: false, dispatch });
    updateInterstitialProgress({ dispatch, progress: 0 });

    updateModalData({ dispatch, data: { error }});
    toggleErrorModal({ dispatch, error });
  }
};
