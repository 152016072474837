import React from 'react';
import { css, keyframes } from '@emotion/core';

import Box from 'components/Box';
import Text from 'components/Text';
import Container from 'components/Container';

import { useStateContext } from 'state';

const LoadingPage = props => {
  const [ state ] = useStateContext();
  const { progress } = state.interstitial;

  return (
    <Container css={takeOver}>
      <Container css={takeOverInner}>

        <div css={animationContainer}>
          <div css={[trapezoidStyles(progress), { animationDelay: '.05s' }]}></div>
          <div css={[trapezoidStyles(progress), { animationDelay: '.10s' }]}></div>
          <div css={[trapezoidStyles(progress), { animationDelay: '.15s' }]}></div>
          <div css={[trapezoidStyles(progress), { animationDelay: '.20s' }]}></div>
          <div css={[trapezoidStyles(progress), { animationDelay: '.25s' }]}></div>
          <div css={[trapezoidStyles(progress), { animationDelay: '.30s' }]}></div>
          <div css={[trapezoidStyles(progress), { animationDelay: '.35s' }]}></div>
          <div css={[trapezoidStyles(progress), { animationDelay: '.40s' }]}></div>
          <div css={[trapezoidStyles(progress), { animationDelay: '.45s' }]}></div>
          <div css={[trapezoidStyles(progress), { animationDelay: '.50s' }]}></div>
        </div>

        <Box mt={200}>
          <Text fontSize={30} css={loadingElipsis}>
            { state.interstitial.message }
          </Text>
        </Box>

      </Container>
    </Container>
  );
}


export default LoadingPage;


const wavey = keyframes`
  0% {
    bottom: 0;
    left: 0;
  }

  50% {
    bottom: 20px;
    left: 5px;
  }

  100% {
    bottom: 0;
    left: 0;
  }
`;

const elipsis = keyframes`
  to {
    width: 25px;
  }
`;

const loadingElipsis = css`
  position: relative;
  color: #FFF;

  &:after {
    content: '…';
    position: absolute;
    width: 0px;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ${elipsis} steps(4, end) 2.5s infinite;
    animation-timing-function: linear;
  }
`;

const takeOver = css`
  max-width: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1;
`;

const takeOverInner = css`
  max-width: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const animationContainer = css`
  background-color: rgba(0,0,0,0.65);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const trapezoidStyles = progress => {
  return css`
    position: relative;
    height: 50px;
    width: 50px;
    border: 3px solid #fff;
    background-color: transparent;
    margin-left: 5px;
    margin-right: 5px;
    transform: skew(-20deg);
    animation: ${wavey} 1.5s ease-in-out infinite;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      background-color: #fff;
      top: ${100 - progress}%;
      transition: top 1200ms ease-in-out;
    }
  `;
};
