import React from 'react';

import Option from 'components/Option';
import Uploader from 'components/Uploader';
import Container from 'components/Container';
import TextBox from 'components/Form/TextBox';
import TextArea from 'components/Form/TextArea';
import Section, { SectionHeading, SectionContent, SectionGrid } from 'components/Section';

import TwitterIcon from 'images/twitter.svg';
import FacebookIcon from 'images/facebook.svg';
import InstagramIcon from 'images/instagram.svg';

import { useStateContext, actions } from 'state';


const BusinessInformation = props => {
  const [state, dispatch] = useStateContext();

  const updateBusinessInfo = ({ group, key }) => ({ target: { value }}) => dispatch({
    group,
    key,
    value,
    type: actions.updateBusinessInfo,
  });

  return (
    <Container {...props}>
      <Section>
        <SectionHeading>Business Information</SectionHeading>

        <SectionContent>
          <TextBox
            name='info.name'
            value={state.info.name}
            label='Name'
            required={true}
            mb={[14, null, 0]}
            pr={[0, null, 12]}
            width={[1, null, 1/2]}
            onChange={updateBusinessInfo({ group: 'info', key: 'name' })}
          />

          <TextBox
            name='info.url'
            value={state.info.url}
            label='Website URL'
            required={true}
            pl={[0, null, 12]}
            width={[1, null, 1/2]}
            onChange={event => {
              const { value: newValue, selectionStart } = event.target;
              const { url } = state.info;
              const beforeStart = selectionStart < 7;
              const wasDelete = url === 'http://' && newValue === 'http:/';


              if (newValue === '') {
                updateBusinessInfo({ group: 'info', key: 'url' })({ target: { value: 'http://' }});
              }

              if (newValue === 'https://') {
                updateBusinessInfo({ group: 'info', key: 'url' })({ target: { value: 'https://' }});
              }

              if (beforeStart || wasDelete) return;

              const value = (/^https?:\/\//.test(newValue))
                ? event.target.value
                : `http://${event.target.value}`;

              updateBusinessInfo({ group: 'info', key: 'url' })({ target: { value }});
            }}
          />
        </SectionContent>
      </Section>


      <Section>
        <SectionHeading>Social Links</SectionHeading>

        <SectionContent>
          <TextBox
            name='social.facebook'
            value={state.social.facebook}
            label={<FacebookIcon width={15} style={socialIconStyles} />}
            mb={[10, null, null, null, 0]}
            pr={[null, null, null, null, 10]}
            width={[1, null, null, null, 1/3]}
            onChange={updateBusinessInfo({ group: 'social', key: 'facebook' })}
            {...socialTextBoxStyles}
          />

          <TextBox
            name='social.twitter'
            value={state.social.twitter}
            label={<TwitterIcon width={15} style={socialIconStyles} />}
            mb={[10, null, null, null, 0]}
            px={[null, null, null, null, '5px']}
            width={[1, null, null, null, 1/3]}
            onChange={updateBusinessInfo({ group: 'social', key: 'twitter' })}
            {...socialTextBoxStyles}
          />

          <TextBox
            name='social.instagram'
            value={state.social.instagram}
            label={<InstagramIcon width={15} style={socialIconStyles} />}
            pl={[null, null, null, null, 10]}
            width={[1, null, null, null, 1/3]}
            onChange={updateBusinessInfo({ group: 'social', key: 'instagram' })}
            {...socialTextBoxStyles}
          />
        </SectionContent>
      </Section>


      <Section>
        <SectionHeading>Sales Notes</SectionHeading>

        <SectionContent>
          <TextArea
            name='info.notes'
            value={state.info.notes}
            onChange={updateBusinessInfo({ group: 'info', key: 'notes' })}
          />
        </SectionContent>
      </Section>


      <Section>
        <SectionHeading>Logo</SectionHeading>

        <SectionContent>
          <Uploader />
        </SectionContent>
      </Section>


      <Section>
        <SectionHeading>Business Type</SectionHeading>

        <SectionGrid>
          {state.data.businessTypes
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(type => (
              <Option
                key={type.handle}
                title={type.title}
                image={type.image}
                selected={state.active.businessType === type.handle}
                onClick={() => dispatch({
                  group: 'active',
                  key: 'businessType',
                  value: type.handle,
                  type: actions.updateBusinessInfo,
                })}
              />
            ))
          }
        </SectionGrid>
      </Section>
    </Container>
  );
};

export default BusinessInformation;

const socialTextBoxStyles = {
  css: { position: 'relative' },
  inputStyles: { paddingLeft: 35 },
  labelStyles: { margin: 0 },
};

const socialIconStyles = {
  fill: '#333',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  paddingLeft: 10,
};