import React from 'react';
import styled from '@emotion/styled-base';
import { Box } from '@rebass/grid/emotion';
import { typography, borders, width, height, size } from 'css/style-props';

import Flex from 'components/Flex';
import Image from 'components/Image';
import Text from 'components/Text';


export default props => {
  return (
    <Option
      px={3}
      py={2}
      css={{ position: 'relative' }}
      {...props}
    >
      <Flex
        flexDirection='column'
        alignItems='center'
        height='200px'
      >

        <Flex
          width={75}
          height={75}
          flexBasis='60%'
          alignItems='center'
        >
          <Image image={props.image} />
        </Flex>

        <Text
          m={0}
          mt={2}
          fontWeight={700}
          color={'#000'}
        >
          {props.title}
        </Text>
      </Flex>
    </Option>
  );
}

const Option = styled(Box)`
  cursor: pointer;
  opacity: ${props => (props.selected) ? 1.0 : 0.25};
  transition: all 300ms ease-in-out;

  ${typography}
  ${borders}
  ${height}
  ${width}
  ${size}
`;

Option.defaultProps = {
  textAlign: 'center',
  border: '1px solid #333',
  borderRadius: '5px',
  fontFamily: 'Sohne Light',
};

Option.propTypes = {
  ...typography.propTypes,
  ...borders.propTypes,
};
