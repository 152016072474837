import React from 'react';
import { css } from '@emotion/core';
import { Flex, Box } from '@rebass/grid/emotion';

import Label from 'components/Form/Label';


export default props => {
  const id = `text-${(Math.random() * 1000000000).toFixed()}`;

  return (
    <Flex
      width={1}
      {...props}
      flexWrap='wrap'
    >

      {props.label &&
        <Label for={id} width={1}>{props.label}</Label>}

      <Box
        as='textarea'
        id={id}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        css={textAreaStyles}
      />

    </Flex>
  );
};

const textAreaStyles = css`
  height: 100px;
  width: 100%;
  font-family: Sohne Light, sans-serif;
  font-weight: 400;
  line-height: 1;
  border: 0;
  background-color: #f4f8ff;
  resize: vertical;
  padding: 10px;
  color: #333;
`;
