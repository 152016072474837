import React from 'react';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import { useStateContext, actions } from 'state';


const Uploader = props => {
  // eslint-disable-next-line
  const [state, dispatch] = useStateContext();

  // called every time a file's `status` changes
  const handleChangeStatus = (fileWithMeta, status) => {
    const { file: logo } = fileWithMeta;
    const removed = ['removed'];
    const success = ['ready', 'done'];
    const noop = ['preparing', 'getting_upload_params', 'headers_received', 'started', 'uploading', 'aborted', 'restarted'];
    const errors = ['rejected_file_type', 'rejected_max_files', 'error_file_size', 'error_validation', 'error_upload_params', 'exception_upload', 'error_upload'];

    if (noop.includes(status)) return;

    if (errors.includes(status)) alert('error');

    if (removed.includes(status)) {
      dispatch({
        group: 'info',
        key: 'logo',
        value: '',
        type: actions.updateBusinessInfo,
      });
    }

    if (success.includes(status)) {
      dispatch({
        group: 'info',
        key: 'logo',
        value: logo,
        type: actions.updateBusinessInfo,
      });
    }
  }

  return (
    <Dropzone
      onChangeStatus={handleChangeStatus}
      autoUpload={false}
      submitButtonContent={null}
      maxFiles={1}
      accept="image/*"
      styles={{
        dropzone: { borderStyle: 'dashed' },
        inputLabel: {
          color: '#333',
          fontFamily: '"Sohne Light",sans-serif',
          fontSize: '14px'
        },
        preview: {
          border: 0,
        }
      }}
      {...props}
    />
  )
};

export default Uploader;
