export const normalizeBusinessTypes = ({ businessType }) => {
  const {
    title,
    presentationId,
    image,
    fields: { handle },
  } = businessType;

  return { title, presentationId, image, handle };
};

export const getActiveBusinessType = state => {
  const { title, handle, presentationId } = state.data.businessTypes
    .find(({ handle }) => handle === state.active.businessType) || {};

  return { title, handle, presentationId };
};
